<!--隐私政策-->
<template>
  <div style="tab-interval:36pt;padding: 0 8px 8px;">
    <div class="Section0"><h1><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:16.0000pt;">[PDBox]<font face="宋体">隐私政策</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:16.0000pt;"></span></b></h1><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">版本更新日期：</font>2022年</span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;">2</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">月</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;">7</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">日</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">版本生效日期：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"><font face="宋体">简介</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">欢迎您使用我们的产品和服务！</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您的信任对我们非常重要，我们深知用户信息安全的重要性，因此我们将尽量以更清晰、更容易被您理解的方式展现本隐私政策，从而希望能够真实地传达我们希望向您传达的信息，并希望您在向我们提供某些信息（其中可能包括您的个人信息）以及允许我们处理并分享某些信息之前，能够清晰地了解这些信息收集的目的、可能的用途以及其他方面的内容。我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本隐私政策旨在帮助您了解我们对您个人信息的处理目的及方式，包括我们会收集哪些数据、为什么收集这些数据，会使用这些数据做些什么及如何保护这些数据等，若您使用我们的的产品和服务，即表示您认同我们在本政策中所述内容，因此我们建议您完整地阅读本隐私权政策，了解维护自己隐私权的方式。如果您有任何疑问、意见或建议，请通过我们提供的各种联系方式与我们联系。如对本声明和政策内容有任何疑问、意见或建议，您可通过</font>http://www.ivisense.com/about/contact/与我们联系。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本隐私政策适用于您通过任何方式使用</font>PDBox平台提供的全部产品和/或服务（本隐私政策中统称为PDBox平台服务）而提供或留存的信息。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">对于您利用我们的产品或者服务上传、下载、发布、分发、存储等通过平台应用处理的数据的数据，因此情况下您对数据享有完全的控制权，上述数据将不适用于本隐私政策。如您利用我们的服务进行数据处理，您须确保数据来源的合法性，如上述数据中涉及个人信息，您须确保上述个人信息的收集符合正当性、合法性、必要性要求，已经向信息主体告知个人信息收集、使用的目的、方式、范围并已经取得信息主体的明示同意，我们将根据您对我们的授权或我们另行获得的信息主体的授权对上述数据进行处理。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">请您确保您向我们提供的个人信息，以及您授权我们在本隐私政策所述范围内收集、处理、使用、存储、共享、转让或公开披露相关个人信息，不会侵犯他人合法权益。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本隐私政策将帮助您了解以下内容：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><font face="宋体">隐私政策</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">的适用范围</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们如何收集您的个人信息</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们如何使用您的个人信息</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">4.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们如何使用</font> Cookie 和同类技术</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">5.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们如何保存及保护您的个人信息</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">6.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">用户业务数据</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">7.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您的权利</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">8.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本隐私政策如何更新</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">9.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如何联系我们</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><o:p>&nbsp;</o:p></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">1 <font face="宋体">本</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;"><font face="宋体">隐私政策</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"><font face="宋体">的适用范围</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">1.1 本隐私政策适用于您通过任何方式使用PDBox平台提供的全部产品和/或服务。服务包括向您提供药品数据上传、机构药品库管理、药品数据治理等产品以及技术服务。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">1.2 特别说明：如果您利用我们的技术服务为您的用户再行提供服务，因您的业务数据为您所有，您应与您的用户另行约定隐私政策。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">2 <font face="宋体">我们如何收集和使用您的个人信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为了向您提供，并使您获得更好、更个性化的产品和服务，当您使用我们的产品和服务时，我们会以下列方式收集您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们会根据本隐私政策的约定，为实现我们的产品和</font>/或服务功能而对所收集的个人信息进行使用。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">注册信息及实名认证信息。您使用我们的产品以及服务之前，需要登录经注册的</font>PDBox通用账号。当您在注册PDBox通用账号时，您需向我们提供能够标识出您身份的信息，经我们审核后，将给您分配平台登录的账号密码。您注册完毕后 ，可以在网页上继续完善您的信息，包括但不限于：电子邮箱信息、</span><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">密保问题信息</font></span></b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">、所在行业信息等。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将不包含可识别到具体个人身份的信息。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">4.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们会对我们的产品与</font>/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息将进行匿名化处理，不包含您的任何身份识别信息。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo2;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">5.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">在您选择公开您的问答内容而需展示您的个人信息时，我们会采用打码、替换、匿名处理方式对您的信息进行脱敏，使您不可被直接识别以保护您的信息安全。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">3 <font face="宋体">我们如何使用您的信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">PDBox会出于本隐私政策所述的以下目的，使用您的个人信息：</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">与您取得联系。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们及我们的授权服务中心将利用您提供的联系方式信息和</font>PDBox服务的使用情况进行产品介绍、服务消息通知并与您进行售前、售中、售后各环节沟通。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为您提供安全保障。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为提高您使用我们产品和服务的安全性，我们可能使用您的信息用于身份验证、客户服务、安全防范等，以预防、发现、调查危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们的其他用户及社会公众的合法权益。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">改善我们的产品和服务，开展内部审计、数据分析和研究。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们使用收集的信息来提供并改进我们提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为改进我们的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，我们可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">4.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">依照法律、法规和监管规定的要求，将您提供的身份标识信息等的真实性进行核验。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">5.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">研究。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们会出于公共利益和科学目的而使用数据进行研究，但会通过相应的技术和组织措施来保护个人的权利和自由。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">6.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">其他用途。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">当我们要将信息用于本隐私政策未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">7.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如超出收集用户信息时所声称的目的，或者在超出具有直接或合理关联的范围使用用户信息前，我们会再次向您告知并征得您的明示同意。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo3;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">8.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">a) 与国家安全、国防安全直接相关的；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">b) 与公共安全、公共卫生、重大公共利益直接相关的；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">c) 与犯罪侦查、起诉、审判和判决执行等直接有关的；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">d) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">e) 所收集的个人信息是您自行向社会公众公开的；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">f) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">g) 根据您的要求向您提供产品或服务所必需的；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">h) 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo4;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;"> <span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">i) 法律法规规定的其他情形。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">4 <font face="宋体">我们如何使用</font><font face="Calibri">-Cookie-</font><font face="宋体">和同类技术</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为确保网站正常运转，我们会在您的计算机或移动设备上存储名为</font> Cookie 的小数据文件。Cookie 是放置在设备上的小型文本文件，用于存储数据，域中放置 Cookie 的 Web 服务器可以调用这些数据。Cookie 通常包含标识符、站点名称以及一些号码和字符。我们使用 Cookie 和类似技术来存储和遵守您的偏好和设置、使您能够登录、提供基于兴趣的广告、打击欺诈行为、分析我们产品的性能以及实现下述其他合法目的。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">当您使用</font>PDBox的产品和服务时，会向您的设备发送Cookie。当您与我们提供给合作伙伴的服务（例如广告和/或推广服务，以及可能显示在其他网站上的由PDBox提供的服务功能）进行交互时，我们允许Cookie（或者其他匿名标识符）发送给PDBox的服务器。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们不会将</font> Cookie 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的PDBox提供的服务或功能。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您可以通过更改您的浏览器设置限制</font>PDBox对Cookie的使用。以Chrome浏览器为例，您可以在Chrome浏览器右上方的下拉菜单的“历史记录”中，通过“清除浏览数据”，选择清除您的Cookie。如需详细了解其他浏览器如何更改浏览器设置，请参考该浏览器的帮助中心或其他相关功能指引页面，例如：https://support.google.com/chrome/search?q=cookie ;http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies； https://support.mozilla.org/zh-CN/products/firefox/protect-your-privacy/cookies。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">5 <font face="宋体">我们如何保存及保护您的个人信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">保存期限</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为了您能够更好地使用</font>PDBox的产品服务并有效处理可能产生的争议，除满足《网络安全法》对于数据留存的强制性要求外，我们将在您使用PDBox产品和服务期间，及相关行为的诉讼时效届满前保存您的个人信息。在超过上述期限后，我们会对您的信息进行删除或进行匿名化处理（即会对您的个人信息进行处理以使您的身份无法被任何第三方识别）。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如出现产品或服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集您的用户信息，并按照上述规则对您的信息进行删除或进行匿名化处理；停止运营的通知将以逐一送达或公告的形式通知用户。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">保存地域</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您的个人信息均储存于中华人民共和国境内。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如部分产品或服务涉及跨境，我们需要向中华人民共和国境外传输您的个人信息，在获得您的授权同意后，您的个人信息可能会被转移到境外管辖区，或者受到来自这些管辖区的访问；以及，因政府监管、司法协助要求等情形，需向中华人民共和国境外传输您的个人信息的，我们会向您告知所涉跨境传输的数据类型、跨境传输目的、接收方及相关安全保障措施等情况，当我们执行此类传输时，我们会根据本隐私政策和数据传输目的地适用的法律、标准，通过合规法律机制（例如合同）来帮助保障您始终享有数据权利和数据保护。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如果该境外管辖区设有不同的数据保护法，或未设立相关法律，我们会尽力保障您的个人信息得到在中华人民共和国境内足够同等的保护。例如，在跨境数据转移之前实施数据去标识化等安全举措。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">安全措施</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们以业界成熟的安全标准和规范收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们非常重视信息安全。我们有专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取符合业界标准的合理可行的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们定期对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们部署访问控制机制，对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们提醒您注意，互联网并非绝对安全的环境，当您通过电子邮件、短信等与其他用户交互信息时，不确定第三方软件对信息的传递是否完全加密，请注意确保您个人信息的安全，我们建议您不要通过此类方式发送个人信息，以免个人信息泄露。请使用复杂密码，协助我们保证您的账号以及个人信息安全。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和</font>/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，由多个部门组成联合应急响应小组处理。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">安全事件通知</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo5;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">6 <font face="宋体">用户业务数据</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您通过</font>PDBox提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。我们作为SaaS平台方，会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您应对您的用户业务数据来源及内容负责，我们提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如您与</font>PDBox协商一致，我们将在您选定的数据中心存储用户业务数据。我们恪守对用户的安全承诺，根据适用的法律保护用户存储在PDBox平台上的数据。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">7 <font face="宋体">您的权利</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们非常重视您对个人信息的关注，并尽全力保护您对您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们保障您对自己的个人信息行使以下权利：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">访问您的个人信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">账户信息</font>——如果您希望访问或编辑您的账户的绑定信息、更改您的密码、进行账户关联等，您可以通过个人中心执行此类操作。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如果上述链接无法访问，您可以通过</font>http://www.ivisense.com/about/contact/与我们取得联系。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">更正您的个人信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您发现我们处理的关于您的个人信息有错误或不完整，您有权对错误或不完整的信息自行作出更正或更新，例如：您可以随时通过个人中心修改您的绑定手机号等信息；可以通过个人中心更新您的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">注销您的账户</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您随时可注销此前注册的账户。您可以通过</font>http://www.ivisense.com/about/contact/联系我们，注销您的PDBox账号。在您要求注销账户后，我们将停止为您提供产品或服务，为您注销账户后，我们将及时删除您的个人信息或做匿名化处理，法律另有规定的情形除外。因法律规定需要留存的用户个人信息，我们会妥善保管，并不再将其应用于业务场景。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">特别提醒：您的</font>PDBox账号一旦注销后，我们将无法协助您找回，请您备份好账号下所有业务信息后谨慎操作。账号注销意味着账号协议及该账号下所有服务条款、协议、合同等一并解除，包括您将无法找回该账号下的个人信息、业务数据、历史信息等。此外，请您知悉并理解，账号注销并不代表您在该账号注销前的所有账号行为和相关责任得到豁免或减轻。</span></b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">响应您的上述请求</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。如果直接实现您的请求需要付出高额成本或存在其他显著困难的，我们将积极努力向您提供替代性方法。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">与我们履行法律法规规定的义务相关的；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">与国家安全、国防安全直接相关的；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">与公共安全、公共卫生、重大公共利益直接相关的；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">4.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">与犯罪侦查、起诉、审判和执行判决等直接相关的；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">5.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">6.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">7.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">涉及商业秘密的；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo6;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">8.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">基于法律法规要求、保障信息安全、确保服务连续性或保证合同正常履行等正当事由，您的部分信息可能无法访问、获取、更正、删除或限制处理。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">8 </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;"><font face="宋体">我们如何委托处理、共享、转让、公开披露您的个人信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"><font face="宋体">委托处理</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"></span></b></h3><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">如本</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">业务功能中</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">某</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">些具体的</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">模块</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">或功能由外部供应商提供。例如，我们会聘请服务</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">提供</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">商</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">来</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">协助我们提供容户支持。对我们委托处理个人信息的公司、组织和</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">个</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"><font face="宋体">共</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"><font face="宋体">享</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:12.0000pt;"></span></b></h3><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"><font face="宋体">转让</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"></span></b></h3><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">(1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">(2）在涉及合并、收购或破产清算时，如涉及个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h3><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"><font face="宋体">公开披露</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:12.0000pt;"></span></b></h3><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们仅会在以下情形下，公开披露您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">(1）获得您明确同意后；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">(2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">。</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><o:p>&nbsp;</o:p></span></p><h2><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;">9 <font face="宋体">我们如何处理儿童的个人信息</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="MsoNormal"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">如果您是未满</font>14 周岁的儿童，则在完成账号注册前，还应请您的监护人仔细阅读专门制定的 《儿童个人信息保护规则及监护人须知》</span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;background:rgb(255,255,0);
      mso-highlight:rgb(255,255,0);"><font face="Cambria">【</font></span></b>
      <b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;background:rgb(255,255,0);
      mso-highlight:rgb(255,255,0);"><span class="msoIns">
        <!-- <ins cite="mailto:Admin" datetime="2022-02-07T10:47">link</ins> -->
        <router-link class="baNum" tag="a" target="_blank" :to="{name:'RuleOfChildren'}">link</router-link>
      </span></span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;background:rgb(255,255,0);
      mso-highlight:rgb(255,255,0);"><font face="Cambria">】</font></span></b>
      <b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">。只有在取得监护人对《儿童个人信息保护规则及监护人须知》的同意后，未满</font>14 周岁的儿童方可使用我们的产品或服务。</span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"></span></b></p><p class="MsoNormal"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">如果没有监护人的同意，儿童不应创建自己的个人信息主体账户。</font></span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"></span></b></p><p class="MsoNormal"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">对于经监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</font></span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"></span></b></p><p class="MsoBodyText"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">如果我们发现自己在</font></span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">未</font></span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">事先获得可证实的监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</font></span></b><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"></span></b></p><h2><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;">10</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"><font face="宋体">本隐私政策如何更新</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们可能适时修订本政策内容，除法律另有规定外，未经您明确同意，我们不会单方面限制您依据本隐私政策所应享有的权利。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们会通过</font>PDBox官网隐私政策页面公布更新版本，并在生效前通过网站公告或其他适当方式提醒您相关内容的更新，您可以随时访问PDBox官网了解最新的隐私政策。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">对于重大变更，我们还会提供更为显著的通知（包括通过页面显著位置提示、向您的账号推送信息、向您发送电子邮件、向您提供弹窗提示，通知您了解隐私权政策的变更内容）。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本隐私政策所指的重大变更包括但不限于：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">个人信息共享、转让或公开披露的主要对象发生变化；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您参与个人信息处理方面的权利及其行使方式发生重大变化；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">4.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo7;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">5.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">其他可能对您的个人信息权益产生重大影响的变化时。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">若您不同意该等变更可以停止使用</font>PDBox平台产品和服务，</span><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;"><font face="Cambria">若您继续使用我们的产品和</font>/或服务，即表示您同意受修订后的本隐私政策的约束，更新后的隐私政策将于我们发布或通知（如有）您之日起的（以较后者为准）的【15】天后生效</span></b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;">11</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">&nbsp;<font face="宋体">如何联系我们</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">对本隐私政策有任何意见或建议，或者对于实现您的权利有疑问，您可通过</font>http://www.ivisense.com/about/contact/反馈联系我们。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您还可以通过如下联络方式同我们联系：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">文件寄达：中国上海市静安区寿阳路</font>99弄9号8层</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">上海卫心科技有限公司</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">邮政编码：</font>200072</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">一般情况下，我们将在验证您的用户身份后的【</font>30】天内回复。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"><font face="宋体">附录</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="52"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">本隐私政策中使用的特定词语，具有如下含义：</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-style:italic;
      font-size:12.0000pt;"><span style="mso-list:Ignore;">1.<span>&nbsp;</span></span></span><!--[endif]--><i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-style:italic;font-size:12.0000pt;">PDBox平台</span></i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">是指由</font></span><i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-style:italic;font-size:12.0000pt;"><font face="Cambria">卫宁健康科技集团股份有限公司</font></span></i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">及其关联子公司</font></span><i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-style:italic;font-size:12.0000pt;"><font face="Cambria">上海卫心科技有限公司</font></span></i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">享有所有权和运营权的</font></span><i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-style:italic;font-size:12.0000pt;">PDBox-药数矩阵网站</span></i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">（</font>https://pdbox.cn）以及移动应用</span><i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-style:italic;font-size:12.0000pt;">PDBox药数矩阵APP</span></i><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">等平台或客户端服务的统称。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">2.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">“我们”、“PDBox”，指上海卫心科技有限公司。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">3.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">“您”，指使用我们的产品和服务的注册用户以及收费服务的购买方。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">4.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">5.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">6.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l1 level1 lfo8;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">7.<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本隐私政策之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p></div>
  </div>
</template>
<script>
  export default {
    name: 'PrivacyPolicy'
  }
</script>
<style scoped>
  @font-face{
  font-family:"Times New Roman";
  }

  @font-face{
  font-family:"����";
  }

  @font-face{
  font-family:"Wingdings";
  }

  @font-face{
  font-family:"Consolas";
  }

  @font-face{
  font-family:"Calibri";
  }

  @font-face{
  font-family:"Cambria";
  }

  @list l0:level1{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:36.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level2{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:72.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level3{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:108.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level4{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:144.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level5{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:180.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level6{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:216.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level7{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:252.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level8{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:288.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level9{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:324.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  p.MsoNormal{
  mso-style-name:����;
  mso-style-parent:"";
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  h1{
  mso-style-name:"���� 1";
  mso-style-next:�����ı�;
  margin-top:24.0000pt;
  margin-bottom:20px;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:1;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-weight:bold;
  font-size:16.0000pt;
  }

  h2{
  mso-style-name:"���� 2";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:20px;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:2;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-weight:bold;
  font-size:14.0000pt;
  }

  h3{
  mso-style-name:"���� 3";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:3;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-weight:bold;
  font-size:12.0000pt;
  }

  h4{
  mso-style-name:"���� 4";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:4;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-style:italic;
  font-size:12.0000pt;
  }

  h5{
  mso-style-name:"���� 5";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:5;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  h6{
  mso-style-name:"���� 6";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:6;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  p.MsoHeading7{
  mso-style-name:"���� 7";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:7;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  p.MsoHeading8{
  mso-style-name:"���� 8";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:8;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  p.Msoheading9{
  mso-style-name:"���� 9";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:9;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  span.10{
  font-family:Wingdings;
  }

  span.15{
  font-family:Wingdings;
  vertical-align:super;
  }

  span.16{
  font-family:Wingdings;
  color:rgb(79,129,189);
  }

  span.17{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.18{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.19{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.20{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.21{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.22{
  font-family:Wingdings;
  }

  span.23{
  font-family:Wingdings;
  }

  span.24{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.25{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.26{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.27{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.28{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.29{
  font-family:Consolas;
  color:rgb(125,144,41);
  font-size:11.0000pt;
  }

  span.30{
  font-family:Consolas;
  color:rgb(6,40,126);
  font-size:11.0000pt;
  }

  span.31{
  font-family:Consolas;
  color:rgb(188,122,0);
  font-size:11.0000pt;
  }

  span.32{
  font-family:Consolas;
  color:rgb(0,112,32);
  font-size:11.0000pt;
  }

  span.33{
  font-family:Consolas;
  color:rgb(187,102,136);
  font-size:11.0000pt;
  }

  span.34{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.35{
  font-family:Consolas;
  color:rgb(102,102,102);
  font-size:11.0000pt;
  }

  span.36{
  font-family:Consolas;
  color:rgb(25,23,124);
  font-size:11.0000pt;
  }

  span.37{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-style:italic;
  font-size:11.0000pt;
  }

  span.38{
  font-family:Consolas;
  color:rgb(144,32,0);
  font-size:11.0000pt;
  }

  span.39{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.40{
  font-family:Consolas;
  color:rgb(136,0,0);
  font-size:11.0000pt;
  }

  span.41{
  font-family:Consolas;
  color:rgb(64,160,112);
  font-size:11.0000pt;
  }

  span.42{
  font-family:Consolas;
  color:rgb(0,112,32);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.43{
  font-family:Consolas;
  color:rgb(64,160,112);
  font-size:11.0000pt;
  }

  span.44{
  font-family:Consolas;
  color:rgb(0,112,32);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.45{
  font-family:Consolas;
  color:rgb(186,33,33);
  font-style:italic;
  font-size:11.0000pt;
  }

  span.46{
  font-family:Consolas;
  color:rgb(64,160,112);
  font-size:11.0000pt;
  }

  span.47{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.48{
  font-family:Consolas;
  color:rgb(255,0,0);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.49{
  font-family:Consolas;
  color:rgb(255,0,0);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.50{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  p.MsoFootnoteText{
  mso-style-name:��ע�ı�;
  mso-style-noshow:yes;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoSubtitle{
  mso-style-name:������;
  mso-style-parent:����;
  mso-style-next:�����ı�;
  margin-top:12.0000pt;
  margin-bottom:12.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(51,91,138);
  font-weight:bold;
  font-size:15.0000pt;
  }

  p.MsoDate{
  mso-style-name:����;
  mso-style-next:�����ı�;
  margin-bottom:10.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoBodyText{
  mso-style-name:�����ı�;
  margin-top:9.0000pt;
  margin-bottom:9.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoTitle{
  mso-style-name:����;
  mso-style-next:�����ı�;
  margin-top:24.0000pt;
  margin-bottom:12.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(51,91,138);
  font-weight:bold;
  font-size:18.0000pt;
  }

  p.MsoBlockText{
  mso-style-name:�ı���;
  mso-style-noshow:yes;
  mso-style-parent:�����ı�;
  mso-style-next:�����ı�;
  margin-top:5.0000pt;
  margin-right:24.0000pt;
  margin-bottom:5.0000pt;
  margin-left:24.0000pt;
  text-indent:0.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoCaption{
  mso-style-name:��ע;
  mso-style-next:����;
  margin-top:0.0000pt;
  margin-bottom:6.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-style:italic;
  font-size:12.0000pt;
  }

  p.58{
  mso-style-name:"Definition Term";
  mso-style-next:Definition;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-weight:bold;
  font-size:12.0000pt;
  }

  p.59{
  mso-style-name:"First Paragraph";
  mso-style-parent:�����ı�;
  mso-style-next:�����ı�;
  margin-top:9.0000pt;
  margin-bottom:9.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.60{
  mso-style-name:Compact;
  mso-style-parent:�����ı�;
  margin-top:1.8000pt;
  margin-bottom:1.8000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.61{
  mso-style-name:"Source Code";
  margin-bottom:10.0000pt;
  word-break:break-all;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.62{
  mso-style-name:"Captioned Figure";
  mso-style-parent:Figure;
  margin-bottom:10.0000pt;
  page-break-after:avoid;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.63{
  mso-style-name:Author;
  mso-style-next:�����ı�;
  margin-bottom:10.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.64{
  mso-style-name:"TOC Heading";
  mso-style-noshow:yes;
  mso-style-parent:"���� 1";
  mso-style-next:�����ı�;
  margin-top:12.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  line-height:107%;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(54,96,145);
  font-weight:normal;
  font-size:16.0000pt;
  }

  p.65{
  mso-style-name:Figure;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.66{
  mso-style-name:"Image Caption";
  mso-style-parent:��ע;
  margin-top:0.0000pt;
  margin-bottom:6.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-style:italic;
  font-size:12.0000pt;
  }

  p.67{
  mso-style-name:Definition;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.68{
  mso-style-name:Abstract;
  mso-style-next:�����ı�;
  margin-top:15.0000pt;
  margin-bottom:15.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:10.0000pt;
  }

  p.69{
  mso-style-name:Bibliography;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.70{
  mso-style-name:"Table Caption";
  mso-style-parent:��ע;
  margin-top:0.0000pt;
  margin-bottom:6.0000pt;
  page-break-after:avoid;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-style:italic;
  font-size:12.0000pt;
  }

  span.msoIns{
  mso-style-type:export-only;
  mso-style-name:"";
  text-decoration:underline;
  text-underline:single;
  color:blue;
  }

  span.msoDel{
  mso-style-type:export-only;
  mso-style-name:"";
  text-decoration:line-through;
  color:red;
  }

  table.MsoNormalTable{
  mso-style-name:��ͨ����;
  mso-style-parent:"";
  mso-style-noshow:yes;
  mso-tstyle-rowband-size:0;
  mso-tstyle-colband-size:0;
  mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin:0pt;
  mso-para-margin-bottom:.0001pt;
  mso-pagination:widow-orphan;
  font-family:'Times New Roman';
  font-size:10.0000pt;
  mso-ansi-language:#0400;
  mso-fareast-language:#0400;
  mso-bidi-language:#0400;
  }

  table.71{
  mso-style-name:Table;
  mso-tstyle-rowband-size:0;
  mso-tstyle-colband-size:0;
  mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin:0pt;
  mso-para-margin-bottom:.0001pt;
  mso-pagination:widow-orphan;
  font-family:'Times New Roman';
  font-size:10.0000pt;
  mso-ansi-language:#0400;
  mso-fareast-language:#0400;
  mso-bidi-language:#0400;
  }

  table.71FirstRow{
  mso-style-name:Table;
  mso-table-condition:first-row;
  mso-tstyle-border-bottom:0.0000pt solid windowtext;
  mso-tstyle-vert-align:bottom;
  }
  @page{mso-page-border-surround-header:no;
    mso-page-border-surround-footer:no;}@page Section0{
  }
  div.Section0{page:Section0;}
</style>
