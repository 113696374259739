var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "tab-interval": "36pt", padding: "0 8px 8px" } },
    [
      _c("div", { staticClass: "Section0" }, [
        _c("h1", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "16.0000pt",
                },
              },
              [
                _vm._v("[PDBox]"),
                _c("font", { attrs: { face: "宋体" } }, [_vm._v("隐私政策")]),
              ],
              1
            ),
          ]),
          _vm._m(0),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("版本更新日期："),
              ]),
              _vm._v("2022年"),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "宋体",
                "mso-ascii-font-family": "Cambria",
                "mso-hansi-font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_vm._v("2")]
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("月")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "宋体",
                "mso-ascii-font-family": "Cambria",
                "mso-hansi-font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_vm._v("7")]
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("日")])],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("版本生效日期："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("简介")])],
              1
            ),
          ]),
          _vm._m(1),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("欢迎您使用我们的产品和服务！"),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "您的信任对我们非常重要，我们深知用户信息安全的重要性，因此我们将尽量以更清晰、更容易被您理解的方式展现本隐私政策，从而希望能够真实地传达我们希望向您传达的信息，并希望您在向我们提供某些信息（其中可能包括您的个人信息）以及允许我们处理并分享某些信息之前，能够清晰地了解这些信息收集的目的、可能的用途以及其他方面的内容。我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "本隐私政策旨在帮助您了解我们对您个人信息的处理目的及方式，包括我们会收集哪些数据、为什么收集这些数据，会使用这些数据做些什么及如何保护这些数据等，若您使用我们的的产品和服务，即表示您认同我们在本政策中所述内容，因此我们建议您完整地阅读本隐私权政策，了解维护自己隐私权的方式。如果您有任何疑问、意见或建议，请通过我们提供的各种联系方式与我们联系。如对本声明和政策内容有任何疑问、意见或建议，您可通过"
                ),
              ]),
              _vm._v("http://www.ivisense.com/about/contact/与我们联系。"),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("本隐私政策适用于您通过任何方式使用"),
              ]),
              _vm._v(
                "PDBox平台提供的全部产品和/或服务（本隐私政策中统称为PDBox平台服务）而提供或留存的信息。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "对于您利用我们的产品或者服务上传、下载、发布、分发、存储等通过平台应用处理的数据的数据，因此情况下您对数据享有完全的控制权，上述数据将不适用于本隐私政策。如您利用我们的服务进行数据处理，您须确保数据来源的合法性，如上述数据中涉及个人信息，您须确保上述个人信息的收集符合正当性、合法性、必要性要求，已经向信息主体告知个人信息收集、使用的目的、方式、范围并已经取得信息主体的明示同意，我们将根据您对我们的授权或我们另行获得的信息主体的授权对上述数据进行处理。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "请您确保您向我们提供的个人信息，以及您授权我们在本隐私政策所述范围内收集、处理、使用、存储、共享、转让或公开披露相关个人信息，不会侵犯他人合法权益。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("o:p")],
            1
          ),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("本隐私政策将帮助您了解以下内容："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(2),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("本")])],
              1
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Cambria",
                  "mso-hansi-font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("隐私政策")])],
              1
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("的适用范围"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(3),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("我们如何收集您的个人信息"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(4),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("我们如何使用您的个人信息"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(5),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("我们如何使用"),
                ]),
                _vm._v(" Cookie 和同类技术"),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(6),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("我们如何保存及保护您的个人信息"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(7),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("用户业务数据"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(8),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("您的权利"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(9),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("本隐私政策如何更新"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo1",
            },
          },
          [
            _vm._m(10),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("如何联系我们"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("o:p")],
            1
          ),
        ]),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("1 "),
                _c("font", { attrs: { face: "宋体" } }, [_vm._v("本")]),
              ],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("隐私政策")])],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("的适用范围")])],
              1
            ),
          ]),
          _vm._m(11),
        ]),
        _vm._m(12),
        _vm._m(13),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("2 "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("我们如何收集和使用您的个人信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(14),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "为了向您提供，并使您获得更好、更个性化的产品和服务，当您使用我们的产品和服务时，我们会以下列方式收集您的个人信息："
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo2",
            },
          },
          [
            _vm._m(15),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("我们会根据本隐私政策的约定，为实现我们的产品和"),
                ]),
                _vm._v("/或服务功能而对所收集的个人信息进行使用。"),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo2",
            },
          },
          [
            _vm._m(16),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "注册信息及实名认证信息。您使用我们的产品以及服务之前，需要登录经注册的"
                  ),
                ]),
                _vm._v(
                  "PDBox通用账号。当您在注册PDBox通用账号时，您需向我们提供能够标识出您身份的信息，经我们审核后，将给您分配平台登录的账号密码。您注册完毕后 ，可以在网页上继续完善您的信息，包括但不限于：电子邮箱信息、"
                ),
              ],
              1
            ),
            _c("b", [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "Cambria",
                    "mso-bidi-font-family": "'Times New Roman'",
                    "font-weight": "bold",
                    "font-size": "12.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "Cambria" } }, [
                    _vm._v("密保问题信息"),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("、所在行业信息等。"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo2",
            },
          },
          [
            _vm._m(17),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将不包含可识别到具体个人身份的信息。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo2",
            },
          },
          [
            _vm._m(18),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("我们会对我们的产品与"),
                ]),
                _vm._v(
                  "/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息将进行匿名化处理，不包含您的任何身份识别信息。"
                ),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo2",
            },
          },
          [
            _vm._m(19),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "在您选择公开您的问答内容而需展示您的个人信息时，我们会采用打码、替换、匿名处理方式对您的信息进行脱敏，使您不可被直接识别以保护您的信息安全。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("3 "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("我们如何使用您的信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(20),
        ]),
        _vm._m(21),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(22),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("与您取得联系。"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l0 level1 lfo4",
            },
          },
          [
            _vm._m(23),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们及我们的授权服务中心将利用您提供的联系方式信息和"
                  ),
                ]),
                _vm._v(
                  "PDBox服务的使用情况进行产品介绍、服务消息通知并与您进行售前、售中、售后各环节沟通。"
                ),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(24),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("为您提供安全保障。"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l0 level1 lfo4",
            },
          },
          [
            _vm._m(25),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "为提高您使用我们产品和服务的安全性，我们可能使用您的信息用于身份验证、客户服务、安全防范等，以预防、发现、调查危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们的其他用户及社会公众的合法权益。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(26),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "改善我们的产品和服务，开展内部审计、数据分析和研究。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l0 level1 lfo4",
            },
          },
          [
            _vm._m(27),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们使用收集的信息来提供并改进我们提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l0 level1 lfo4",
            },
          },
          [
            _vm._m(28),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "为改进我们的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，我们可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(29),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "依照法律、法规和监管规定的要求，将您提供的身份标识信息等的真实性进行核验。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(30),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("研究。")])],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l0 level1 lfo4",
            },
          },
          [
            _vm._m(31),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们会出于公共利益和科学目的而使用数据进行研究，但会通过相应的技术和组织措施来保护个人的权利和自由。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(32),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("其他用途。"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l0 level1 lfo4",
            },
          },
          [
            _vm._m(33),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "当我们要将信息用于本隐私政策未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(34),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "如超出收集用户信息时所声称的目的，或者在超出具有直接或合理关联的范围使用用户信息前，我们会再次向您告知并征得您的明示同意。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo3",
            },
          },
          [
            _vm._m(35),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意："
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _vm._m(36),
        _vm._m(37),
        _vm._m(38),
        _vm._m(39),
        _vm._m(40),
        _vm._m(41),
        _vm._m(42),
        _vm._m(43),
        _vm._m(44),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("4 "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("我们如何使用"),
                ]),
                _c("font", { attrs: { face: "Calibri" } }, [
                  _vm._v("-Cookie-"),
                ]),
                _c("font", { attrs: { face: "宋体" } }, [_vm._v("和同类技术")]),
              ],
              1
            ),
          ]),
          _vm._m(45),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "为确保网站正常运转，我们会在您的计算机或移动设备上存储名为"
                ),
              ]),
              _vm._v(
                " Cookie 的小数据文件。Cookie 是放置在设备上的小型文本文件，用于存储数据，域中放置 Cookie 的 Web 服务器可以调用这些数据。Cookie 通常包含标识符、站点名称以及一些号码和字符。我们使用 Cookie 和类似技术来存储和遵守您的偏好和设置、使您能够登录、提供基于兴趣的广告、打击欺诈行为、分析我们产品的性能以及实现下述其他合法目的。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [_vm._v("当您使用")]),
              _vm._v(
                "PDBox的产品和服务时，会向您的设备发送Cookie。当您与我们提供给合作伙伴的服务（例如广告和/或推广服务，以及可能显示在其他网站上的由PDBox提供的服务功能）进行交互时，我们允许Cookie（或者其他匿名标识符）发送给PDBox的服务器。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("我们不会将"),
              ]),
              _vm._v(
                " Cookie 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的PDBox提供的服务或功能。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("您可以通过更改您的浏览器设置限制"),
              ]),
              _vm._v(
                "PDBox对Cookie的使用。以Chrome浏览器为例，您可以在Chrome浏览器右上方的下拉菜单的“历史记录”中，通过“清除浏览数据”，选择清除您的Cookie。如需详细了解其他浏览器如何更改浏览器设置，请参考该浏览器的帮助中心或其他相关功能指引页面，例如：https://support.google.com/chrome/search?q=cookie ;http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCookies； https://support.mozilla.org/zh-CN/products/firefox/protect-your-privacy/cookies。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("5 "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("我们如何保存及保护您的个人信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(46),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("保存期限")])],
              1
            ),
          ]),
          _vm._m(47),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("为了您能够更好地使用"),
              ]),
              _vm._v(
                "PDBox的产品服务并有效处理可能产生的争议，除满足《网络安全法》对于数据留存的强制性要求外，我们将在您使用PDBox产品和服务期间，及相关行为的诉讼时效届满前保存您的个人信息。在超过上述期限后，我们会对您的信息进行删除或进行匿名化处理（即会对您的个人信息进行处理以使您的身份无法被任何第三方识别）。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "如出现产品或服务停止运营的情形，我们会采取合理措施保护您用户信息安全，包括及时停止继续收集您的用户信息，并按照上述规则对您的信息进行删除或进行匿名化处理；停止运营的通知将以逐一送达或公告的形式通知用户。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("保存地域")])],
              1
            ),
          ]),
          _vm._m(48),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("您的个人信息均储存于中华人民共和国境内。"),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "如部分产品或服务涉及跨境，我们需要向中华人民共和国境外传输您的个人信息，在获得您的授权同意后，您的个人信息可能会被转移到境外管辖区，或者受到来自这些管辖区的访问；以及，因政府监管、司法协助要求等情形，需向中华人民共和国境外传输您的个人信息的，我们会向您告知所涉跨境传输的数据类型、跨境传输目的、接收方及相关安全保障措施等情况，当我们执行此类传输时，我们会根据本隐私政策和数据传输目的地适用的法律、标准，通过合规法律机制（例如合同）来帮助保障您始终享有数据权利和数据保护。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "如果该境外管辖区设有不同的数据保护法，或未设立相关法律，我们会尽力保障您的个人信息得到在中华人民共和国境内足够同等的保护。例如，在跨境数据转移之前实施数据去标识化等安全举措。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("安全措施")])],
              1
            ),
          ]),
          _vm._m(49),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们以业界成熟的安全标准和规范收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们非常重视信息安全。我们有专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取符合业界标准的合理可行的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们定期对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们部署访问控制机制，对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们提醒您注意，互联网并非绝对安全的环境，当您通过电子邮件、短信等与其他用户交互信息时，不确定第三方软件对信息的传递是否完全加密，请注意确保您个人信息的安全，我们建议您不要通过此类方式发送个人信息，以免个人信息泄露。请使用复杂密码，协助我们保证您的账号以及个人信息安全。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和"
                ),
              ]),
              _vm._v(
                "/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，由多个部门组成联合应急响应小组处理。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("安全事件通知"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(50),
        ]),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo5",
            },
          },
          [
            _vm._m(51),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo5",
            },
          },
          [
            _vm._m(52),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo5",
            },
          },
          [
            _vm._m(53),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("6 "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("用户业务数据"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(54),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [_vm._v("您通过")]),
              _vm._v(
                "PDBox提供的服务，加工、存储、上传、下载、分发以及通过其他方式处理的数据，均为您的用户业务数据，您完全拥有您的用户业务数据。我们作为SaaS平台方，会严格执行您的指示处理您的业务数据，除按与您协商一致或执行明确的法律法规要求外，不对您的业务数据进行任何非授权的使用或披露。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "您应对您的用户业务数据来源及内容负责，我们提示您谨慎判断数据来源及内容的合法性。因您的用户业务数据内容违反法律法规、部门规章或国家政策而造成的全部结果及责任均由您自行承担。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [_vm._v("如您与")]),
              _vm._v(
                "PDBox协商一致，我们将在您选定的数据中心存储用户业务数据。我们恪守对用户的安全承诺，根据适用的法律保护用户存储在PDBox平台上的数据。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("7 "),
                _c("font", { attrs: { face: "宋体" } }, [_vm._v("您的权利")]),
              ],
              1
            ),
          ]),
          _vm._m(55),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们非常重视您对个人信息的关注，并尽全力保护您对您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("我们保障您对自己的个人信息行使以下权利："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("访问您的个人信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(56),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息："
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [_vm._v("账户信息")]),
              _vm._v(
                "——如果您希望访问或编辑您的账户的绑定信息、更改您的密码、进行账户关联等，您可以通过个人中心执行此类操作。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("如果上述链接无法访问，您可以通过"),
              ]),
              _vm._v("http://www.ivisense.com/about/contact/与我们取得联系。"),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("更正您的个人信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(57),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "您发现我们处理的关于您的个人信息有错误或不完整，您有权对错误或不完整的信息自行作出更正或更新，例如：您可以随时通过个人中心修改您的绑定手机号等信息；可以通过个人中心更新您的个人信息。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("注销您的账户"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(58),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("您随时可注销此前注册的账户。您可以通过"),
              ]),
              _vm._v(
                "http://www.ivisense.com/about/contact/联系我们，注销您的PDBox账号。在您要求注销账户后，我们将停止为您提供产品或服务，为您注销账户后，我们将及时删除您的个人信息或做匿名化处理，法律另有规定的情形除外。因法律规定需要留存的用户个人信息，我们会妥善保管，并不再将其应用于业务场景。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("特别提醒：您的"),
                ]),
                _vm._v(
                  "PDBox账号一旦注销后，我们将无法协助您找回，请您备份好账号下所有业务信息后谨慎操作。账号注销意味着账号协议及该账号下所有服务条款、协议、合同等一并解除，包括您将无法找回该账号下的个人信息、业务数据、历史信息等。此外，请您知悉并理解，账号注销并不代表您在该账号注销前的所有账号行为和相关责任得到豁免或减轻。"
                ),
              ],
              1
            ),
          ]),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("响应您的上述请求"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(59),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。如果直接实现您的请求需要付出高额成本或存在其他显著困难的，我们将积极努力向您提供替代性方法。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "在以下情形中，按照法律法规要求，我们将无法响应您的上述请求："
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(60),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("与我们履行法律法规规定的义务相关的；"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(61),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("与国家安全、国防安全直接相关的；"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(62),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("与公共安全、公共卫生、重大公共利益直接相关的；"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(63),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("与犯罪侦查、起诉、审判和执行判决等直接相关的；"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(64),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(65),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(66),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("涉及商业秘密的；"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo6",
            },
          },
          [
            _vm._m(67),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "基于法律法规要求、保障信息安全、确保服务连续性或保证合同正常履行等正当事由，您的部分信息可能无法访问、获取、更正、删除或限制处理。"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c("h2", [
          _vm._m(68),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("我们如何委托处理、共享、转让、公开披露您的个人信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(69),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("委托处理")])],
              1
            ),
          ]),
          _vm._m(70),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("如本")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("业务功能中"),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("某")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("些具体的")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("模块")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("或功能由外部供应商提供。例如，我们会聘请服务"),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("提供")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("商")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("来")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "协助我们提供容户支持。对我们委托处理个人信息的公司、组织和"
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("个")])],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("共")])],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("享")])],
              1
            ),
          ]),
          _vm._m(71),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("转让")])],
              1
            ),
          ]),
          _vm._m(72),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外："
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _vm._m(73),
        _vm._m(74),
        _c("h3", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("公开披露")])],
              1
            ),
          ]),
          _vm._m(75),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("我们仅会在以下情形下，公开披露您的个人信息："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _vm._m(76),
        _c("p", { staticClass: "MsoNormal" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _vm._v(
                "(2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息"
              ),
            ]
          ),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("。")])],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "宋体",
              "mso-ascii-font-family": "Cambria",
              "mso-hansi-font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("o:p")],
            1
          ),
        ]),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "宋体",
                  "mso-ascii-font-family": "Calibri",
                  "mso-hansi-font-family": "Calibri",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v("9 "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("我们如何处理儿童的个人信息"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(77),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("如果您是未满"),
                ]),
                _vm._v(
                  "14 周岁的儿童，则在完成账号注册前，还应请您的监护人仔细阅读专门制定的 《儿童个人信息保护规则及监护人须知》"
                ),
              ],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                  background: "rgb(255,255,0)",
                  "mso-highlight": "rgb(255,255,0)",
                },
              },
              [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("【")])],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                  background: "rgb(255,255,0)",
                  "mso-highlight": "rgb(255,255,0)",
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "msoIns" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "baNum",
                        attrs: {
                          tag: "a",
                          target: "_blank",
                          to: { name: "RuleOfChildren" },
                        },
                      },
                      [_vm._v("link")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                  background: "rgb(255,255,0)",
                  "mso-highlight": "rgb(255,255,0)",
                },
              },
              [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("】")])],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "。只有在取得监护人对《儿童个人信息保护规则及监护人须知》的同意后，未满"
                  ),
                ]),
                _vm._v("14 周岁的儿童方可使用我们的产品或服务。"),
              ],
              1
            ),
          ]),
          _vm._m(78),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "如果没有监护人的同意，儿童不应创建自己的个人信息主体账户。"
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(79),
        ]),
        _c("p", { staticClass: "MsoNormal" }, [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "对于经监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。"
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(80),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("如果我们发现自己在"),
                ]),
              ],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("未")])],
              1
            ),
          ]),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "事先获得可证实的监护人同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。"
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(81),
        ]),
        _c("h2", [
          _vm._m(82),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("本隐私政策如何更新"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(83),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "我们可能适时修订本政策内容，除法律另有规定外，未经您明确同意，我们不会单方面限制您依据本隐私政策所应享有的权利。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("我们会通过"),
              ]),
              _vm._v(
                "PDBox官网隐私政策页面公布更新版本，并在生效前通过网站公告或其他适当方式提醒您相关内容的更新，您可以随时访问PDBox官网了解最新的隐私政策。"
              ),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "对于重大变更，我们还会提供更为显著的通知（包括通过页面显著位置提示、向您的账号推送信息、向您发送电子邮件、向您提供弹窗提示，通知您了解隐私权政策的变更内容）。"
                ),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("本隐私政策所指的重大变更包括但不限于："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo7",
            },
          },
          [
            _vm._m(84),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo7",
            },
          },
          [
            _vm._m(85),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("个人信息共享、转让或公开披露的主要对象发生变化；"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo7",
            },
          },
          [
            _vm._m(86),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "您参与个人信息处理方面的权利及其行使方式发生重大变化；"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo7",
            },
          },
          [
            _vm._m(87),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v(
                    "我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；"
                  ),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo7",
            },
          },
          [
            _vm._m(88),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("其他可能对您的个人信息权益产生重大影响的变化时。"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("若您不同意该等变更可以停止使用"),
              ]),
              _vm._v("PDBox平台产品和服务，"),
            ],
            1
          ),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-weight": "bold",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("若您继续使用我们的产品和"),
                ]),
                _vm._v(
                  "/或服务，即表示您同意受修订后的本隐私政策的约束，更新后的隐私政策将于我们发布或通知（如有）您之日起的（以较后者为准）的【15】天后生效"
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("。")])],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h2", [
          _vm._m(89),
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [
                _vm._v(" "),
                _c("font", { attrs: { face: "宋体" } }, [
                  _vm._v("如何联系我们"),
                ]),
              ],
              1
            ),
          ]),
          _vm._m(90),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v(
                  "对本隐私政策有任何意见或建议，或者对于实现您的权利有疑问，您可通过"
                ),
              ]),
              _vm._v("http://www.ivisense.com/about/contact/反馈联系我们。"),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("您还可以通过如下联络方式同我们联系："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("文件寄达：中国上海市静安区寿阳路"),
              ]),
              _vm._v("99弄9号8层"),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("上海卫心科技有限公司"),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("邮政编码："),
              ]),
              _vm._v("200072"),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("p", { staticClass: "MsoBodyText" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("一般情况下，我们将在验证您的用户身份后的【"),
              ]),
              _vm._v("30】天内回复。"),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c("h2", [
          _c("b", [
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Calibri",
                  "mso-fareast-font-family": "宋体",
                  "mso-bidi-font-family": "'Times New Roman'",
                  color: "rgb(79,129,189)",
                  "font-weight": "bold",
                  "font-size": "14.0000pt",
                },
              },
              [_c("font", { attrs: { face: "宋体" } }, [_vm._v("附录")])],
              1
            ),
          ]),
          _vm._m(91),
        ]),
        _c("p", { staticClass: "52" }, [
          _c(
            "span",
            {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            },
            [
              _c("font", { attrs: { face: "Cambria" } }, [
                _vm._v("本隐私政策中使用的特定词语，具有如下含义："),
              ]),
            ],
            1
          ),
          _c("span", {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          }),
        ]),
        _c(
          "p",
          {
            staticClass: "MsoNormal",
            staticStyle: {
              "margin-left": "36.0000pt",
              "text-indent": "-24.0000pt",
              "mso-list": "l1 level1 lfo8",
            },
          },
          [
            _vm._m(92),
            _vm._m(93),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [_c("font", { attrs: { face: "Cambria" } }, [_vm._v("是指由")])],
              1
            ),
            _c("i", [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "Cambria",
                    "mso-bidi-font-family": "'Times New Roman'",
                    "font-style": "italic",
                    "font-size": "12.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "Cambria" } }, [
                    _vm._v("卫宁健康科技集团股份有限公司"),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("及其关联子公司"),
                ]),
              ],
              1
            ),
            _c("i", [
              _c(
                "span",
                {
                  staticStyle: {
                    "mso-spacerun": "'yes'",
                    "font-family": "Cambria",
                    "mso-bidi-font-family": "'Times New Roman'",
                    "font-style": "italic",
                    "font-size": "12.0000pt",
                  },
                },
                [
                  _c("font", { attrs: { face: "Cambria" } }, [
                    _vm._v("上海卫心科技有限公司"),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("享有所有权和运营权的"),
                ]),
              ],
              1
            ),
            _vm._m(94),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [_vm._v("（")]),
                _vm._v("https://pdbox.cn）以及移动应用"),
              ],
              1
            ),
            _vm._m(95),
            _c(
              "span",
              {
                staticStyle: {
                  "mso-spacerun": "'yes'",
                  "font-family": "Cambria",
                  "mso-bidi-font-family": "'Times New Roman'",
                  "font-size": "12.0000pt",
                },
              },
              [
                _c("font", { attrs: { face: "Cambria" } }, [
                  _vm._v("等平台或客户端服务的统称。"),
                ]),
              ],
              1
            ),
            _c("span", {
              staticStyle: {
                "mso-spacerun": "'yes'",
                "font-family": "Cambria",
                "mso-bidi-font-family": "'Times New Roman'",
                "font-size": "12.0000pt",
              },
            }),
          ]
        ),
        _vm._m(96),
        _vm._m(97),
        _vm._m(98),
        _vm._m(99),
        _vm._m(100),
        _vm._m(101),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "16.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("2."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("3."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("4."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("5."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("6."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("7."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("8."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("9."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "52" }, [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        },
        [
          _vm._v(
            "1.1 本隐私政策适用于您通过任何方式使用PDBox平台提供的全部产品和/或服务。服务包括向您提供药品数据上传、机构药品库管理、药品数据治理等产品以及技术服务。"
          ),
        ]
      ),
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "MsoBodyText" }, [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        },
        [
          _vm._v(
            "1.2 特别说明：如果您利用我们的技术服务为您的用户再行提供服务，因您的业务数据为您所有，您应与您的用户另行约定隐私政策。"
          ),
        ]
      ),
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("2."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("3."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("4."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("5."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "52" }, [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        },
        [_vm._v("PDBox会出于本隐私政策所述的以下目的，使用您的个人信息：")]
      ),
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("2."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("3."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("4."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("5."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("6."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("7."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("8."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("a) 与国家安全、国防安全直接相关的；")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("b) 与公共安全、公共卫生、重大公共利益直接相关的；")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("c) 与犯罪侦查、起诉、审判和判决执行等直接有关的；")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "d) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("e) 所收集的个人信息是您自行向社会公众公开的；")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "f) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("g) 根据您的要求向您提供产品或服务所必需的；")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "h) 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l0 level1 lfo4",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_c("span", { staticStyle: { "mso-list": "Ignore" } }, [_c("span")])]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("i) 法律法规规定的其他情形。")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("2."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("3."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("2."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("3."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("4."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("5."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("6."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("7."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("8."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Calibri",
            "mso-fareast-font-family": "宋体",
            "mso-bidi-font-family": "'Times New Roman'",
            color: "rgb(79,129,189)",
            "font-weight": "bold",
            "font-size": "14.0000pt",
          },
        },
        [_vm._v("8 ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "宋体",
          "mso-ascii-font-family": "Calibri",
          "mso-hansi-font-family": "Calibri",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "宋体",
          "mso-ascii-font-family": "Calibri",
          "mso-hansi-font-family": "Calibri",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "MsoNormal" }, [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        },
        [
          _vm._v(
            "(1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；"
          ),
        ]
      ),
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "MsoBodyText" }, [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        },
        [
          _vm._v(
            "(2）在涉及合并、收购或破产清算时，如涉及个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。"
          ),
        ]
      ),
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "宋体",
          "mso-ascii-font-family": "Calibri",
          "mso-hansi-font-family": "Calibri",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "MsoNormal" }, [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        },
        [_vm._v("(1）获得您明确同意后；")]
      ),
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-weight": "bold",
          "font-size": "12.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "宋体",
            "mso-ascii-font-family": "Calibri",
            "mso-hansi-font-family": "Calibri",
            "mso-bidi-font-family": "'Times New Roman'",
            color: "rgb(79,129,189)",
            "font-weight": "bold",
            "font-size": "14.0000pt",
          },
        },
        [_vm._v("10")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("2."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("3."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("4."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("5."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "宋体",
            "mso-ascii-font-family": "Calibri",
            "mso-hansi-font-family": "Calibri",
            "mso-bidi-font-family": "'Times New Roman'",
            color: "rgb(79,129,189)",
            "font-weight": "bold",
            "font-size": "14.0000pt",
          },
        },
        [_vm._v("11")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("span", {
        staticStyle: {
          "mso-spacerun": "'yes'",
          "font-family": "Calibri",
          "mso-fareast-font-family": "宋体",
          "mso-bidi-font-family": "'Times New Roman'",
          color: "rgb(79,129,189)",
          "font-weight": "bold",
          "font-size": "14.0000pt",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticStyle: {
          "font-family": "Cambria",
          "mso-bidi-font-family": "'Times New Roman'",
          "font-style": "italic",
          "font-size": "12.0000pt",
        },
      },
      [
        _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
          _vm._v("1."),
          _c("span"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-style": "italic",
            "font-size": "12.0000pt",
          },
        },
        [_vm._v("PDBox平台")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-style": "italic",
            "font-size": "12.0000pt",
          },
        },
        [_vm._v("PDBox-药数矩阵网站")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", [
      _c(
        "span",
        {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-style": "italic",
            "font-size": "12.0000pt",
          },
        },
        [_vm._v("PDBox药数矩阵APP")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l1 level1 lfo8",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
              _vm._v("2."),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [_vm._v("“我们”、“PDBox”，指上海卫心科技有限公司。")]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l1 level1 lfo8",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
              _vm._v("3."),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "“您”，指使用我们的产品和服务的注册用户以及收费服务的购买方。"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l1 level1 lfo8",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
              _vm._v("4."),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l1 level1 lfo8",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
              _vm._v("5."),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l1 level1 lfo8",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
              _vm._v("6."),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "MsoNormal",
        staticStyle: {
          "margin-left": "36.0000pt",
          "text-indent": "-24.0000pt",
          "mso-list": "l1 level1 lfo8",
        },
      },
      [
        _c(
          "span",
          {
            staticStyle: {
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _c("span", { staticStyle: { "mso-list": "Ignore" } }, [
              _vm._v("7."),
              _c("span"),
            ]),
          ]
        ),
        _c(
          "span",
          {
            staticStyle: {
              "mso-spacerun": "'yes'",
              "font-family": "Cambria",
              "mso-bidi-font-family": "'Times New Roman'",
              "font-size": "12.0000pt",
            },
          },
          [
            _vm._v(
              "“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本隐私政策之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。"
            ),
          ]
        ),
        _c("span", {
          staticStyle: {
            "mso-spacerun": "'yes'",
            "font-family": "Cambria",
            "mso-bidi-font-family": "'Times New Roman'",
            "font-size": "12.0000pt",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }